import React, { useEffect, useMemo, useState } from 'react'

import { MaintenanceWindow } from '@src/app/components/MaintenanceWindow'
import { DataTrackingProvider } from '@src/app/contexts/DataTrackingContext'
import { useAppDispatch, useAppSelector } from '@src/app/hooks'
import { NabooFFContextProvider } from '@src/common/contexts/NabooFeatureFlag'
import { useFacebookTracking, useGoogleTracking } from '@src/common/hooks'
import usePageViewDataTracking from '@src/common/hooks/usePageViewDataTracking'
import { useRequestState } from '@src/common/hooks/useRequestState'
import { makeGetRequest } from '@src/common/redux/selectors/request'
import { initEmbedApp } from '@src/common/utils/embeddedApp'
import { sessionStorageProvider } from '@src/common/utils/storageProvider'
import '@src/localization/i18n'
import { CookiesProvider } from 'react-cookie'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { BusinessProvider } from '../../common/contexts/BusinessContext'
import { createSession } from '../../common/redux/session'
import api from '../../common/utils/api'
import { REQUEST_STATUS } from '../../constants'
import AppLayout from '../components/AppLayout'
import UnauthenticatedRoutes from '../components/UnauthenticatedRoutes'

import 'react-cmdk/dist/cmdk.css'

/* A function that is used to initialize the embedded app. */
initEmbedApp()

const token = api.getToken()

/**
 * Adding Local storage and Session storage fallbacks to the window object
 * @type {{}}
 */

function Root(): JSX.Element {
  const dispatch = useAppDispatch()
  const [authenticated, setAuthenticated] = useState(!!token)
  const getRequest = useMemo(makeGetRequest, [])
  const { state: createSessionRequest } = useAppSelector((state) =>
    getRequest(state, 'session/createSession')
  )
  const { gtagTrack } = useGoogleTracking()
  const { fbTrack } = useFacebookTracking()
  const { pathname } = useLocation()
  const { state } = useRequestState('business/fetchBusiness')
  const navigate = useNavigate()
  usePageViewDataTracking()

  //Needs to put amplitude_id into the session storage before redirect
  useEffect(() => {
    const url = new URL(window.location.href)
    const params = url.searchParams
    const deviceId = params.get('amplitude_id')
    if (deviceId) {
      sessionStorageProvider.setItem('amplitude_id', deviceId)
    }
  }, [])

  useEffect(() => {
    if (token) {
      dispatch(createSession())
      gtagTrack('event', 'conversion', {
        send_to: 'AW-856325107/1yZKCKP7-fUBEPP3qZgD'
      })
      gtagTrack('event', 'conversion', {
        send_to: 'AW-411548285/iRRICNrXiPgBEP30nsQB'
      })
      fbTrack('track', 'Lead')
    }
  }, [dispatch, fbTrack, gtagTrack])

  useEffect(() => {
    if (createSessionRequest === 'rejected') {
      setAuthenticated(false)
    }
  }, [createSessionRequest])

  /**
   * Inject story block script
   */
  useEffect(() => {
    const script = document.createElement('script')
    script.async = true
    script.type = 'text/javascript'
    script.dataset['fwparam_cookies_root'] = 'false'
    script.dataset['fwparam_pip'] = 'false'
    script.dataset['fwparam_livestream_firstparty'] = 'true'
    script.dataset['fwparam_force_replay'] = 'true'
    script.dataset['fwparam_show_ads'] = 'false'
    script.dataset['fwparam_tap_to_watch'] = 'false'
    if (process.env.REACT_APP_ENV === 'production') {
      script.src = '//asset.fwbiz1.com/js/fwn.js'
    } else {
      script.src = 'https://asset-staging.fireworktv.com/js/fwn.js'
    }
    document.head.appendChild(script)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  /* Route guard to stop access to business and their pages if user has no access */
  useEffect(() => {
    if (state === REQUEST_STATUS.REJECTED) {
      navigate('/')
    }
  }, [navigate, state])

  return (
    <>
      {authenticated ? (
        <CookiesProvider>
          <BusinessProvider>
            <NabooFFContextProvider>
              <MaintenanceWindow>
                <DataTrackingProvider>
                  <AppLayout>
                    <Outlet />
                  </AppLayout>
                </DataTrackingProvider>
              </MaintenanceWindow>
            </NabooFFContextProvider>
          </BusinessProvider>
        </CookiesProvider>
      ) : (
        <UnauthenticatedRoutes />
      )}
    </>
  )
}

export default Root
